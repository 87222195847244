import { Controller } from "stimulus"
import Inputmask from "inputmask"

export default class extends Controller {
   static targets = ["xnop"]

   initialize(){
       Inputmask("99.99.999.999.999.9999.9").mask(this.xnopTarget)
   }
       
}	