import { Controller } from "stimulus"
import L from "leaflet"
import * as GeoSearch from "leaflet-geosearch"

export default class extends Controller {
  static targets = ["container", "address", "latitude", "longitude", "xnop"]

  initialize(){
     Inputmask("99.99.999.999.999.9999.9").mask(this.xnopTarget)
  }

  connect() {
    let defaultLocation = [-6.9830957, 110.4107381]

    if (this.latitudeTarget.value.length > 0 && this.longitudeTarget.value.length > 0) {
      defaultLocation = [this.latitudeTarget.value, this.longitudeTarget.value]
    }

    //this.map = L.map(this.containerTarget).setView(defaultLocation, 18);
    this.map = L.map(this.containerTarget).fitWorld();
    const provider = new GeoSearch.OpenStreetMapProvider();

    L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
         maxZoom: 19,
         attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);

    const search = new GeoSearch.GeoSearchControl({
      style: 'bar',
      provider: provider,
      marker: {
        draggable: true,
      },
    });

    this.map.addControl(search);

    //this.map.locate({setView: true, maxZoom: 16});

    //this.map.on('locationfound', this.onLocationFound);
    this.map.on('locationfound', (event) => {

      console.log(event.latitude)
      
      this.latitudeTarget.value = event.latitude
      this.longitudeTarget.value = event.longitude  
      var locationMarker = L.marker(event.latlng,{draggable:'true'}).addTo(this.map); 
      
      locationMarker.on('dragend', this.geser)
//      locationMarker.on('dragend', function(eventm) {         
//         console.log(eventm)
//         console.log(this.parents)
//         var markerm = eventm.target
//         var position = markerm.getLatLng()
//         console.log(position)
//         console.log(position.lat)
//         this.latitudeTarget.value = position.lat
//         this.longitudeTarget.value = position.lng 
//      });

    });   

    this.map.on('locationerror', this.onLocationError); 

    this.map.locate({setView: true, maxZoom: 16});

    this.map.on('geosearch/showlocation', (event) => {
      let latLng = event.marker.getLatLng()

      this.addressTarget.value = event.location.label
      this.latitudeTarget.value = latLng.lat
      this.longitudeTarget.value = latLng.lng
    });

    this.map.on('geosearch/marker/dragend', (event) => {
      this.latitudeTarget.value = event.location.lat
      this.longitudeTarget.value = event.location.lng
    })

    if (this.addressTarget.value.length > 0) {
      let query = { query: this.addressTarget.value }

      provider.search(query).then((result) => {
        search.showResult(result[0], query)
      });

      search.searchElement.input.value = this.addressTarget.value
    }
  }

  disconnect(){
    this.map.remove()
  }

  geser(e){
    //console.log(e)
    //console.log(e.target)
    console.log(e.target._latlng.lat)
    console.log(e.target._latlng.lng)
    this.latitudeTarget.value = e.target._latlng.lat
    this.longitudeTarget.value = e.target._latlng.lng

  }

onLocationFound(e) {    
    //console.log(e)
    //let radius = e.accuracy;    
    //this.map = L.map(this.containerTarget).setView(e.latlng, 8);  
    //this.latitudeTarget.value = e.latitude
    //this.longitudeTarget.value = e.longitude
    setTimeout(function(){
       //var locationMarker = L.marker(e.latlng).addTo(this.map);
      this.latitudeTarget.value = '5' //e.latitude
      this.longitudeTarget.value = '5' //e.longitude
    }, 2000);
}  

onLocationError(e) {
    alert(e.message);
}

}