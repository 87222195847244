import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "xnop", "latitude", "longitude", "address", "pilsatu", "xcbetul"]

  initialize(){
     Inputmask("99.99.999.999.999.9999.9").mask(this.xnopTarget)
     this.pilsatuTarget.hidden = true
  }

  connect() {
    window.addEventListener("google-maps-callback", this.initMap.bind(this));

  }

  initMap() {
    
    const semarangCoords = { lat: -6.966667, lng: 110.416664 };

    const mapOptions = {
      center: semarangCoords,
      zoom: 11
    }
    this.map = new google.maps.Map(this.containerTarget, mapOptions)

    // Create the DIV to hold the control and call the CenterControl() constructor
    const controlDiv = document.createElement('div');
    this.createLocationButton(controlDiv);

    // Add the control to the map
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(controlDiv);


  }

  createLocationButton(controlDiv) {
    const controlUI = document.createElement('div');
    controlUI.className = 'custom-map-control-button';
    controlUI.title = 'Klik untuk menengahkan peta';
    controlUI.innerHTML = 'Lokasi ku';
    controlDiv.appendChild(controlUI);

    // Setup the click event listeners
    controlUI.addEventListener('click', () => {
      this.updateLocation();
    });
  }

  updateLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }

          this.map.setCenter(pos);
          this.map.setZoom(18);

          // Add a marker at the current location
          const marker = new google.maps.Marker({
            position: pos,
            map: this.map,
            title: "You are here",
            draggable: true
          });

          // Add event listener for dragging the marker
          marker.addListener('dragend', (event) => {
            this.updateMarkerLocation(event.latLng, marker);
          });          

          this.latitudeTarget.value = position.coords.latitude
          this.longitudeTarget.value = position.coords.longitude

        },
        () => {
          this.handleLocationError(true, this.map.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError(false, this.map.getCenter());
    }
  }

  updateMarkerLocation(location, marker) {
    marker.setPosition(location);
    this.map.setCenter(location);

    //const infoWindow = new google.maps.InfoWindow({
    //  content: `Lat: ${location.lat()}, Lng: ${location.lng()}`
    //});
    //infoWindow.open(this.map, marker);
    this.latitudeTarget.value = location.lat()
    this.longitudeTarget.value = location.lng()
  }

  handleLocationError(browserHasGeolocation, pos) {
    const infoWindow = new google.maps.InfoWindow({
      map: this.map
    });
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    this.map.setCenter(pos);
  }

  pilihcek(e){
    e.preventDefault()    
    this.pilsatuTarget.hidden = true

    if (this.xcbetulTarget.checked) {
       this.pilsatuTarget.hidden = false
    } else {
       this.pilsatuTarget.hidden = true
    }

  }

}